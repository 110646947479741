


import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSync, faEye, faArrowUp, faArrowDown,  faTarp, faSave, faCancel, faPager, faPrint} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './PedidosData.css'
import 'jspdf-autotable';
import baseURL from '../../url';
import moneda from '../../moneda';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import contador from '../../contador'
import NewContact from '../NewContact/NewContact';


export default function PedidosData() {
    const [pedidos, setPedidos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalPasarelaPago, setModalPasarelaPago] = useState(false);
    const [nuevoEstado, setNuevoEstado] = useState('');
    const [pedido, setPedido] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');
       const [telefono, setTelefono] = useState([]);
    const [filtroId, setFiltroId] = useState('');
        const [filtroTelefono, setFiltroTelefono] = useState('');
    const [filtroEstado, setFiltroEstado] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    
    const [contacto, setContactos] = useState([]);

     // Inicio Obtener la fecha actual en formato YYYY-MM-DD: Ramon Cabrera
     const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [filtroDesde, setFiltroDesde] = useState(getCurrentDate());
    const [filtroHasta, setFiltroHasta] = useState(getCurrentDate());
    // FIN Obtener la fecha actual en formato YYYY-MM-DD: Ramon Cabrera



    useEffect(() => {
        cargarPedidos();
     
        cargarContacto()
    }, []);


    let nombreContacto = '';
    let direccionContacto = '';
    let telefonoContacto = '';
   
    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto || []);
                nombreContacto= contacto.nombre
                direccionContacto = contacto.direccion
                telefonoContacto = contacto.telefono
                
                
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };


 
    const cargarPedidos = () => {
        fetch(`${baseURL}/pedidoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setPedidos(data.pedidos.reverse() || []);
                console.log(data.pedidos)
            })
            .catch(error => console.error('Error al cargar pedidos:', error));
    };
   

    const eliminar = (idPedido) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/pedidoDelete.php?idPedido=${idPedido}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarPedidos();
                    })
                    .catch(error => {
                        console.error('Error al eliminar :', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setPedido(item);
        setNuevoEstado(item.estado)
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const abrirModalPasarelaPago = (item) => {
        setPedido(item);
        setNuevoEstado(item.estado)
        setModalPasarelaPago(true);
    };

    const cerrarModalPasarelaPago = () => {
        setModalPasarelaPago(false);
        setMontoRecibido('')
        setMontoDevolver(0)
    };


 
    
   

    const handleUpdateText = (idPedido) => {
        const payload = {
            estado: nuevoEstado !== '' ? nuevoEstado : pedido.estado,
               };

        fetch(`${baseURL}/pedidoPut.php?idPedido=${idPedido}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarPedidos();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };

  
    
  
    const handleUpdateTextCobro = (idPedido, nuevoMontoRecibido, nuevoMontoDevolver) => {
        const payload = {
          MRecibido: nuevoMontoRecibido !== '' ? nuevoMontoRecibido : pedidos.montoRecibido,
          MDevuelto: nuevoMontoDevolver !== '' ? nuevoMontoDevolver : pedidos.montoDevuelto, // Asegúrate de que este nombre coincide con el que espera tu API
          estado: 'Pagado',
        };
      
        console.log('Payload:', payload);
      
        fetch(`${baseURL}/pedidoPut.php?idPedido=${idPedido}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then(response => response.json())
          .then(data => {
            if (data.error) {
              Swal.fire('Error!', data.error, 'error');
            } else {
                        Swal.fire({
                            title: 'Cobrado correctamente!',
                            text: data.mensaje,
                            icon: 'success',
                        }).then(() => {
                            // Aquí se ejecuta después de que se cierre la alerta
                            handleDownloadPDFImpresora();
                        });
      
              cargarPedidos();
              cerrarModalPasarelaPago();
            }
          })
          .catch(error => {
            console.log(error.message);
            toast.error(error.message);
          });
      };
      

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    const filtrados = pedidos.filter(item => {
        const idMatch = item.idPedido.toString().includes(filtroId);
            const estadoMatch = !filtroEstado || item.estado.includes(filtroEstado);
        const desdeMatch = !filtroDesde || new Date(item.createdAt) >= new Date(filtroDesde);

        // Incrementamos la fecha "hasta" en un día para que incluya la fecha seleccionada
        const adjustedHasta = new Date(filtroHasta);
        adjustedHasta.setDate(adjustedHasta.getDate() + 1);

        const hastaMatch = !filtroHasta || new Date(item.createdAt) < adjustedHasta;
        return idMatch  && estadoMatch && desdeMatch && hastaMatch;
    });


    const recargarProductos = () => {
        cargarPedidos();
    };
    const invertirOrden = () => {
        setPedidos([...pedidos].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const descargarExcel = () => {
        let totalGeneral = 0;

        const data = filtrados.map(item => {
            const total = parseFloat(item.total); // Convertir a número
            totalGeneral += total;
            const productos = JSON.parse(item.productos);
            const infoProductos = productos.map(producto => `${producto.categoria} - ${producto.titulo} - ${moneda}${producto.precio} - x${producto.cantidad}  `);
            return {
                'ID Pedido': item.idPedido,
              
                'Estado': item.estado,
                'Nombre': item.nombre,
                'Nota': item.nota,
                'Productos': infoProductos.join('\n'),
                'Codigo': item.codigo,
                'Total': `${moneda} ${total.toFixed(2)}`,
                'Fecha': item.createdAt,
            };
        });

        // Formatear el total general
        const formattedTotal = `${moneda} ${totalGeneral.toFixed(2)}`;

        // Agregar fila con el total general
        const totalRow = {

            'ID Pedido': '',
        
            'Estado': '',
            'Nombre': '',
            'Nota': '',
            'Productos': '',
            'Codigo': 'Total General:',
            'Total': formattedTotal,
            'Fecha': '',
        };

        data.push(totalRow);

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'pedidos');
        XLSX.writeFile(wb, 'pedidos.xlsx');
    };


    
    const descargarPDF = () => {
        const pdf = new jsPDF('landscape'); // Orientación horizontal
        pdf.text('Lista de Pedidos', 10, 10);

        const columns = [
            { title: 'ID Pedido', dataKey: 'idPedido' },
        
            { title: 'Estado', dataKey: 'estado' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Nota', dataKey: 'nota' },
            { title: 'Productos', dataKey: 'productos' },
            { title: 'Codigo', dataKey: 'codigo' },
            { title: 'Total', dataKey: 'total' },
            { title: 'Fecha', dataKey: 'createdAt' },
        ];

        let totalGeneral = 0;

        const data = filtrados.map(item => {
            const total = parseFloat(item.total); // Convertir a número
            totalGeneral += total;
            const productos = JSON.parse(item.productos);
            const infoProductos = productos.map(producto => `${producto.categoria} - ${producto.titulo} - ${moneda}${producto.precio} - x${producto.cantidad}  `);
            return {
                idPedido: item.idPedido,
            
                estado: item.estado,
                nombre: item.nombre,
                nota: item.nota,
                productos: infoProductos.join('\n'),
                codigo: item.codigo,
                total: `${moneda} ${total.toFixed(2)}`,
                createdAt: item.createdAt,
            };
        });

        // Formatear el total general
        const formattedTotal = `${moneda} ${totalGeneral.toFixed(2)}`;

        // Agregar fila con el total general
        const totalRow = {
            idPedido: '',

            estado: '',
            nombre: '',
            nota: '',
            productos: '',
            codigo: 'Total General:',
            total: formattedTotal,
            createdAt: '',
        };

        data.push(totalRow);

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('pedidos.pdf');
    };


   


 // INICIO CODIGO PARA GENERAR PDF TIPO TICKET RAMON CABRERA
 //ESTE CODIGO NO LO ESTAMOS USANDO. LO DEJÉ AQUÍ PORQUE PUEDE SER DE UTILIDAD MÁS ADELANTE
   const handleDownloadPDF = () => {
   const nombreEmpresa = 'HR TEINDA';
    const direccionEmpresa = 'DIRECCION HR TIENDA ';
    const contactoEmpresa = '\n 000-000-000';
    
   
    const pdf = new jsPDF('p', 'mm', [80, 200]); // Tamaño personalizado para tamaño tipo ticket

    // Datos de la empresa
    pdf.setFontSize(18);
    pdf.text(nombreEmpresa, 10, 10);
    pdf.setFontSize(10);
    pdf.text(direccionEmpresa, 10, 15);
    pdf.text(contactoEmpresa, 10, 20);

    // Línea horizontal
    pdf.line(10, 25, 70, 25);

    let y = 30; // Posición inicial en y para el contenido

    // Detalles del pedido
    const pedidoActual = pedido;


    // Mostrar detalles del pedido
    pdf.setFontSize(8);
    pdf.setFont("helvetica", "bold");
    pdf.text('ID Pedido:', 10, y);

    pdf.text('Estado:', 10, y + 10);
    pdf.text('Nombre:', 10, y + 15);
    pdf.text('Nota:', 10, y + 20);
    pdf.setFont("helvetica", "normal");

    y += 25; // Espacio antes del contenido de la nota

    // Imprimir los detalles del pedido
    pdf.text(`${pedidoActual.idPedido}`, 30, y - 25);
 
    pdf.text(`${pedidoActual.estado}`, 30, y - 15);
    pdf.text(`${pedidoActual.nombre}`, 30, y - 10);

    // Imprimir el campo Nota con ajuste de texto automático
    const notaTexto = ` ${pedidoActual.nota}`;
    const notaLines = pdf.splitTextToSize(notaTexto, 60); // Ajuste el ancho a 60 mm
    notaLines.forEach((line, index) => {
        pdf.text(line, 10, y + index * 5); // Ajuste la posición vertical
    });

    // Desplazar etiquetas "Código" y "Fecha"
    y += 4 + notaLines.length * 5; // Ajuste de espacio después de la nota

    pdf.setFont("helvetica", "bold");
    pdf.text('Código descuento:', 10, y);
    pdf.text('Fecha:', 10, y + 5);
    pdf.setFont("helvetica", "normal");

    pdf.text(`${pedidoActual.codigo}`, 40, y);
    pdf.text(`${pedidoActual.createdAt}`, 30, y + 5);

    y += 15; // Ajuste de espacio antes de la línea discontinua

    // Línea horizontal discontinua
    pdf.setLineDash([1, 1], 0); // Definir el patrón de la línea discontinua
    pdf.line(10, y, 70, y);
    pdf.setLineDash(); // Restablecer el patrón de línea

    y += 7; // Ajuste de espacio antes de la sección de productos

    // Encabezado de la tabla en negrita
    pdf.setFontSize(8);
    pdf.setFont("helvetica", "bold");
    pdf.text('Producto', 10, y);
    pdf.text('Cantidad', 58, y, { align: 'right' });
    pdf.text('Precio', 62, y);
    pdf.setFont("helvetica", "normal");

    y += 5; // Espacio después del encabezado

    // Obtener los productos del pedido actual
    const productosPedido = JSON.parse(pedidoActual.productos);

    // Inicializar subtotal
    let subtotal = 0;

    // Generar tabla de productos
    pdf.setFontSize(8);
    for (let i = 0; i < productosPedido.length; i++) {
        if (y + 10 > pdf.internal.pageSize.height) {
            pdf.addPage();
            y = 10; // Reiniciar y en la nueva página
            // Encabezado de la tabla en la nueva página
            pdf.setFont("helvetica", "bold");
            pdf.text('Producto', 10, y);
            pdf.text('Cantidad', 58, y, { align: 'right' });
            pdf.text('Precio', 62, y);
            pdf.setFont("helvetica", "normal");
            y += 5; // Espacio después del encabezado
        }

        const producto = productosPedido[i];

        // Ajuste de longitud para evitar distorsión
        const productoTexto = producto.titulo.length > 27 ? producto.titulo.substring(0, 27) + '...' : producto.titulo;

        // Calcular subtotal por producto
        const productoSubtotal = producto.cantidad * producto.precio;
        subtotal += productoSubtotal;

        // Mostrar texto de producto, cantidad y precio sin espacio adicional
        pdf.text(productoTexto, 10, y);
        pdf.text(String(producto.cantidad).padStart(4, ' '), 58, y, { align: 'right' });
        pdf.text(String(producto.precio), 62, y);

        y += 5; // Incrementar y para la siguiente fila
    }

    // Línea horizontal discontinua antes del subtotal
    y += 0; // Espacio antes de la línea discontinua
    pdf.setLineDash([1, 1], 0); // Definir el patrón de la línea discontinua
    pdf.line(10, y, 70, y);
    pdf.setLineDash(); // Restablecer el patrón de línea

    // Agregar subtotal
    y += 5; // Espacio antes del subtotal
    pdf.setFont("helvetica", "bold");
    pdf.text('Subtotal:', 10, y);
    pdf.text(`${moneda} ${subtotal.toFixed(2)}`, 68, y, { align: 'right' });
    pdf.setFont("helvetica", "normal");

    // Calcular descuento
    const total = parseFloat(pedidoActual.total.replace(/[^0-9.-]+/g, '')); // Extraer valor numérico del total
    const descuento = subtotal - total; // Descuento como la diferencia

    // Agregar descuento
    y += 5; // Espacio antes del descuento
    pdf.setFont("helvetica", "bold");
    pdf.text('Descuento:', 10, y);
    pdf.text(`${moneda} ${descuento.toFixed(2)}`, 68, y, { align: 'right' });
    pdf.setFont("helvetica", "normal");

    // Agregar total al final
    y += 5; // Espacio antes del total
    pdf.setFont("helvetica", "bold");
    pdf.text('Total:', 10, y);
    pdf.text(`${moneda} ${total.toFixed(2)}`, 68, y, { align: 'right' });
    pdf.setFont("helvetica", "normal");

    // Guardar el PDF
    pdf.save('pedido.pdf');
};
 // FIN CODIGO PARA GENERAR PDF TIPO TICKET RAMON CABRERA
    

 
 //INICIO IMPRIMIR POR DEFECTO A LA IMPRESORA RAMON CABRERA
 const handleDownloadPDFImpresora = async () => {
    try {
      const nombreEmpresa = 'KELO PARRILLA';
      const direccionEmpresa = 'Kelo Supermarket  1177 HADDON AVE \n CAMDEN  NJ 08105 ';
      const contactoEmpresa = '\n 856-813-7987';
  
      const pdf = new jsPDF('p', 'mm', [80, 200]); // Tamaño personalizado para tamaño tipo ticket
  
      // Datos de la empresa
      pdf.setFontSize(18);
      pdf.text(nombreEmpresa, 10, 10);
      pdf.setFontSize(10);
      pdf.text(direccionEmpresa, 10, 15);
      pdf.text(contactoEmpresa, 10, 20);
  
      // Línea horizontal
      pdf.line(10, 25, 70, 25);
  
      let y = 30; // Posición inicial en y para el contenido
  
      // Detalles del pedido
      const pedidoActual = pedido;

  
      // Mostrar detalles del pedido
      pdf.setFontSize(8);
      pdf.setFont("helvetica", "bold");
      pdf.text('ID Pedido:', 10, y);
      pdf.text('Teléfono:', 10, y + 5);
      pdf.text('Estado:', 10, y + 10);
      pdf.text('Nombre:', 10, y + 15);
      pdf.text('Nota:', 10, y + 20);
      pdf.setFont("helvetica", "normal");
  
      y += 25; // Espacio antes del contenido de la nota
  
      // Imprimir los detalles del pedido
      pdf.text(`${pedidoActual.idPedido}`, 30, y - 25);
      pdf.text(`${pedidoActual?.telefono}`, 30, y - 20);
      //pdf.text(`${pedidoActual.estado}`, 30, y - 15);
      pdf.text(`Pagado`, 30, y - 15);
      pdf.text(`${pedidoActual.nombre}`, 30, y - 10);
  
      // Imprimir el campo Nota con ajuste de texto automático
      const notaTexto = ` ${pedidoActual.nota}`;
      const notaLines = pdf.splitTextToSize(notaTexto, 60); // Ajuste el ancho a 60 mm
      notaLines.forEach((line, index) => {
        pdf.text(line, 10, y + index * 5); // Ajuste la posición vertical
      });
  
      // Desplazar etiquetas "Código" y "Fecha"
      y += 4 + notaLines.length * 5; // Ajuste de espacio después de la nota
  
      pdf.setFont("helvetica", "bold");
      pdf.text('Código descuento:', 10, y);
      pdf.text('Fecha:', 10, y + 5);
      pdf.setFont("helvetica", "normal");
  
      pdf.text(`${pedidoActual.codigo}`, 40, y);
      pdf.text(`${pedidoActual.createdAt}`, 30, y + 5);
  
      y += 15; // Ajuste de espacio antes de la línea discontinua
  
      // Línea horizontal discontinua
      pdf.setLineDash([1, 1], 0); // Definir el patrón de la línea discontinua
      pdf.line(10, y, 70, y);
      pdf.setLineDash(); // Restablecer el patrón de línea
  
      y += 7; // Ajuste de espacio antes de la sección de productos
  
      // Encabezado de la tabla en negrita
      pdf.setFontSize(8);
      pdf.setFont("helvetica", "bold");
      pdf.text('Producto', 10, y);
      pdf.text('Cantidad', 58, y, { align: 'right' });
      pdf.text('Precio', 62, y);
      pdf.setFont("helvetica", "normal");
  
      y += 5; // Espacio después del encabezado
  
      // Obtener los productos del pedido actual
      const productosPedido = JSON.parse(pedidoActual.productos);
  
      // Inicializar subtotal
      let subtotal = 0;
  
      // Generar tabla de productos
      pdf.setFontSize(8);
      for (let i = 0; i < productosPedido.length; i++) {
        if (y + 10 > pdf.internal.pageSize.height) {
          pdf.addPage();
          y = 10; // Reiniciar y en la nueva página
          // Encabezado de la tabla en la nueva página
          pdf.setFont("helvetica", "bold");
          pdf.text('Producto', 10, y);
          pdf.text('Cantidad', 58, y, { align: 'right' });
          pdf.text('Precio', 62, y);
          pdf.setFont("helvetica", "normal");
          y += 5; // Espacio después del encabezado
        }
  
        const producto = productosPedido[i];
  
        // Ajuste de longitud para evitar distorsión
        const productoTexto = producto.titulo.length > 27 ? producto.titulo.substring(0, 27) + '...' : producto.titulo;
  
        // Calcular subtotal por producto
        const productoSubtotal = producto.cantidad * producto.precio;
        subtotal += productoSubtotal;
  
        // Mostrar texto de producto, cantidad y precio sin espacio adicional
        pdf.text(productoTexto, 10, y);
        pdf.text(String(producto.cantidad).padStart(4, ' '), 58, y, { align: 'right' });
        pdf.text(String(producto.precio), 62, y);
  
        y += 5; // Incrementar y para la siguiente fila
      }
  
      // Línea horizontal discontinua antes del subtotal
      y += 0; // Espacio antes de la línea discontinua
      pdf.setLineDash([1, 1], 0); // Definir el patrón de la línea discontinua
      pdf.line(10, y, 70, y);
      pdf.setLineDash(); // Restablecer el patrón de línea
  
      // Agregar subtotal
      y += 5; // Espacio antes del subtotal
      pdf.setFont("helvetica", "bold");
      pdf.text('Subtotal:', 10, y);
      pdf.text(`${moneda} ${subtotal.toFixed(2)}`, 68, y, { align: 'right' });
      pdf.setFont("helvetica", "normal");
  
      // Calcular descuento
      const total = parseFloat(pedidoActual.total.replace(/[^0-9.-]+/g, '')); // Extraer valor numérico del total
      const descuento = subtotal - total; // Descuento como la diferencia
  
      // Agregar descuento
      y += 5; // Espacio antes del descuento
      pdf.setFont("helvetica", "bold");
      pdf.text('Descuento:', 10, y);
      pdf.text(`${moneda} ${descuento.toFixed(2)}`, 68, y, { align: 'right' });
      pdf.setFont("helvetica", "normal");
  
      // Agregar total al final
      y += 5; // Espacio antes del total
      pdf.setFont("helvetica", "bold");
      pdf.text('Total:', 10, y);
      pdf.text(`${moneda} ${total.toFixed(2)}`, 68, y, { align: 'right' });
      pdf.setFont("helvetica", "normal");
  
      // Imprimir el PDF directamente en la impresora predeterminada
      const pdfData = pdf.output('blob');
      const printWindow = window.open(URL.createObjectURL(pdfData), '_blank');
  
      if (printWindow) {
        printWindow.print();
      } else {
        console.error('No se pudo abrir la ventana de impresión.');
      }
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    }
  };
   
 //FIN DE IMPRIMIR POR DEFECTO A LA IMPRESORA

    const [counter, setCounter] = useState(contador);
    const [isPaused, setIsPaused] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                setCounter((prevCounter) => {
                    if (prevCounter === 1) {
                        recargar();
                        return contador;
                    }
                    return prevCounter - 1;
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPaused]);
    const togglePause = () => {
        setIsPaused(!isPaused);
    };


    const recargar = () => {
              cargarPedidos();
    };

    //Ramon cabrera
    //codigo para calcular el monto a devolver
    const [montoRecibido, setMontoRecibido] = useState('');
    const [montoDevolver, setMontoDevolver] = useState(0);
    const handleMontoRecibidoChange = (event) => {
        const recibido = parseFloat(event.target.value) || 0;
        setMontoRecibido(recibido);
        setMontoDevolver(recibido - pedido.total);

        
    };
    // fin del calcula para el monto a devolver
    

   

    return (
                    <div>

                        <ToastContainer />
                        <div className='deFlexContent'>

                            <div className='deFlex2'>

                                <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                                <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                            </div>
                            <div className='filtrosContain'>
                                        <div className='inputsColumn'>
                                                <input
                                                    type="date"
                                                    value={filtroDesde}
                                                    onChange={(e) => setFiltroDesde(e.target.value)}
                                                    placeholder='Desde'
                                                />
                                       </div>


                                       <div className='inputsColumn'>
                                                <input
                                                    type="date"
                                                    value={filtroHasta}
                                                    onChange={(e) => setFiltroHasta(e.target.value)}
                                                    placeholder='Hasta'
                                                />
                                     </div>



                                <div className='inputsColumn'>
                                    <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='Id Pedido' />
                                </div>
                                
                                <div className='inputsColumn'>
                                    <select value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
                                        <option value="">Estado</option>
                                        <option value="Entregado">Entregado</option>
                                        <option value="Rechazado">Rechazado</option>
                                        <option value="Pagado">Pagado</option>
                                        <option value="Pendiente">Pendiente</option>
                                    </select>
                                </div>
                                <button className='reload' onClick={recargarProductos}><FontAwesomeIcon icon={faSync} /></button>
                                <button className='reverse' onClick={invertirOrden}>
                                    {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                                </button>

                            </div>

                        </div>

                        {
                            //CODIGO PARA EL ENCABEZADO DE  LA TABLA DE FILTRADO DE LOS PEDIDOS
                        }
                        <div className='table-container'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Id Pedido</th>
                                        <th>Teléfono</th>
                                        <th>Estado</th>
                                        <th>Nombre</th>
                                        <th>Nota</th>
                                        <th>Codigo</th>
                                        <th>Total</th>
                                        <th>Fecha</th>
                                        <th>Acciones</th>
                                        
                                    </tr>
                                </thead>
                            
                                {
                            //CODIGO PARA EL CUERPO DE  LA TABLA DE FILTRADO DE LOS PEDIDOS
                        }

                                <tbody>
                                    {filtrados.map(item => (
                                        <tr key={item.idPedido}>
                                        <td>{item.idPedido}</td>
                                      
                                        <td>{item.telefono}</td>

                                        <td style={{
                                            color: item?.estado === 'Pendiente' ? '#DAA520' :
                                            item?.estado === 'Entregado' ? '#0000FF' :
                                                item?.estado === 'Rechazado' ? '#FF0000' :
                                                item?.estado === 'Pagado' ? '#008000' :
                                                    '#000000'
                                        }}>
                                            {item?.estado}
                                        </td>

                                        <td>{item.nombre}</td>
                                        <td>{item.nota}</td>
                                        <td>{item.codigo}</td>
                                        <td style={{ color: '#008000' }}>{moneda} {item.total}</td>
                                        <td>{item.createdAt}</td>
                                        <td>
                                            <button title="Eliminar" className='eliminar' onClick={() => eliminar(item.idPedido)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            </button>

                                            <button title="Actualizar" className='editar' onClick={() => abrirModal(item)}>
                                            <FontAwesomeIcon icon={faEye} />
                                            </button>


                                            {
                                                //CODIGO PARA ABRIR EL MODAL DE PASARELA DE PAGO
                                            }                                                                          

                                            <button title="Facturar"
                                            className='editar' 
                                            
                                            onClick={() => {
                                                if (item.estado !== 'Pagado') {
                                                abrirModalPasarelaPago(item);
                                                } else {
                                                // Opcional: Puedes mostrar un mensaje o realizar alguna acción si el estado es 'Pagado'
                                                Swal.fire({
                                                    title: 'Este pedido fue pagado!',
                                                    text: 'Facturación',
                                                    icon: 'success',
                                                    iconHtml: '!',
                                                    customClass: {
                                                      icon: 'swal2-warning-icon'
                                                    }
                                                  });
                                                }
                                            }}
                                            
                                            >
                                           <FontAwesomeIcon icon={faPrint}  />
                                          
                                           
                                            </button>
                                            

                                            
                                        </td>



                                        </tr>
                                    ))}
                            </tbody>




                            </table>
                        </div>

                        {//MODAL PARA VISUALIZAR Y EDITAR EL ESTADO DEL PEDIDO//
                        }
                        {modalVisible && (
                            <div className="modal">
                                <div className="modal-content">
                                    <div className='deFlexBtnsModal'>
                                        <div className='deFlexBtnsModal'>
                                            <button
                                                className={selectedSection === 'texto' ? 'selected' : ''}
                                                onClick={() => handleSectionChange('texto')}
                                            >
                                                Pedido
                                            </button>
                                            <button onClick={handleDownloadPDF} className='texto'>Descargar PDF</button>
                                        </div>

                                        <span className="close" onClick={cerrarModal}>
                                            &times;
                                        </span>
                                    </div>
                                    <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                                        <div className='flexGrap'>
                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
      
                                            <fieldset>
                                                <legend>ID Pedido</legend>
                                                <input
                                                    value={pedido.idPedido}
                                                    disabled

                                                />
                                            </fieldset>

                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Teléfono </legend>
                                                <input
                                                    value={pedido.telefono}
                                                    disabled

                                                />                


                                            </fieldset>
                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Nombre</legend>
                                                <input
                                                    value={pedido.nombre}
                                                    disabled

                                                />
                                            </fieldset>
                                     {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Codigo</legend>
                                                <input
                                                    value={pedido.codigo}
                                                    disabled

                                                />
                                            </fieldset>

                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Nota</legend>
                                                <input
                                                    value={pedido.nota}
                                                    disabled

                                                />
                                            </fieldset>

                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Fecha </legend>
                                                <input
                                                    value={pedido.createdAt}
                                                    disabled

                                                />
                                            </fieldset>
                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Total </legend>
                                                <input
                                                    value={pedido.total}
                                                    disabled

                                                />
                                            </fieldset>
                                            {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                            }
                                            <fieldset>
                                                <legend>Estado</legend>
                                                <select
                                                    value={nuevoEstado !== '' ? nuevoEstado : pedido.estado}
                                                    onChange={(e) => setNuevoEstado(e.target.value)}
                                                >
                                                    <option value={pedido.estado}>{pedido.estado}</option>
                                                    <option value="Entregado">Entregado</option>
                                                    <option value="Rechazado">Rechazado</option>
                                                    <option value="Pagado">Pagado</option>
                                                </select>
                                            </fieldset>
                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL DE LOS PRODUCTOS EN EL PEDIDO
                                            }
                                            <div className='cardsProductData'>
                                                {JSON.parse(pedido.productos).map(producto => (
                                                    <div key={producto.titulo} className='cardProductData'>
                                                        <img src={producto.imagen} alt="imagen" />
                                                        <div className='cardProductDataText'>
                                                            <h3>{producto.titulo}</h3>
                                                            <strong>{moneda} {producto.precio} <span>x{producto.cantidad}</span></strong>
                                                            <span>{producto.item}</span>
                                                            <h5>{producto.categoria}</h5>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>


                                        </div>
                                        <button className='btnPost' onClick={() => handleUpdateText(pedido.idPedido)} >Guardar </button>
                                    </div>
                                </div>
                            </div>
                        )}



{//MODAL PARA LA PASARELA DE PAGO//
}
                            {modalPasarelaPago && (
                                                        <div className="modal">
                                                            <div className="modal-content">
                                                                <div className='deFlexBtnsModal'>
                                                                    <div className='deFlexBtnsModal'>
                                                                        <button
                                                                            className={selectedSection === 'texto' ? 'selected' : ''}
                                                                            onClick={() => handleSectionChange('texto')}
                                                                        >
                                                                           PASARELA DE PAGO
                                                                        </button>


                                                                      {// BLOQUEE ESTE BOTON PORQUE NO ES NECESARIO EN ESTE MODAL
                                                                      // <button onClick={handleDownloadPDF} className='texto'>Descargar PDF</button>
                                                                       }  
                                                                    
                                                                    
                                                                    </div>

                                                                    <span className="close" onClick={cerrarModalPasarelaPago}>
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                                <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                                                                    <div className='flexGrap'>
                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                
                                                                        <fieldset>
                                                                            <legend>ID Pedido</legend>
                                                                            <input
                                                                                value={pedido.idPedido}
                                                                                disabled

                                                                            />
                                                                        </fieldset>

                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Teléfono </legend>
                                                                           
                                                                             <input
                                                                                    value={pedido.telefono}
                                                                                    disabled

                                                                            />

                                                                        </fieldset>
                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Nombre</legend>
                                                                            <input
                                                                                value={pedido.nombre}
                                                                                disabled

                                                                            />
                                                                        </fieldset>
                                                                {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Codigo</legend>
                                                                            <input
                                                                                value={pedido.codigo}
                                                                                disabled

                                                                            />
                                                                        </fieldset>

                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Nota</legend>
                                                                            <input
                                                                                value={pedido.nota}
                                                                                disabled

                                                                            />
                                                                        </fieldset>

                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Fecha </legend>
                                                                            <input
                                                                                value={pedido.createdAt}
                                                                                disabled

                                                                            />
                                                                        </fieldset>
                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Total </legend>
                                                                            <input
                                                                                value={pedido.total}
                                                                                disabled

                                                                            />
                                                                        </fieldset>
                                                                        {// ESTE CODIGO DEVUELVE EL VALOR A LOS IMPUTS EN EL MODAL
                                                                        }
                                                                        <fieldset>
                                                                            <legend>Estado</legend>
                                                                            <select
                                                                                value={nuevoEstado !== '' ? nuevoEstado : pedido.estado}
                                                                                onChange={(e) => setNuevoEstado(e.target.value)}                                                                           >
                                                                               
                                                                              
                                                                                <option value="Pagado">Pagado</option>
                                                                               

                                                                            </select>
                                                                                                                                                                                                                  

                                                                        </fieldset>

                                                                        {// EN ESTE CODIGO VOY A DIGITAR EL MONTO RECIBIDO POR EL CLIENTE
                                                                        }
                                                                        <fieldset>
                                                                                <legend>MONTO RECIBIDO</legend>
                                                                                <input
                                                                                    type="text"
                                                                                    id="montoRecibido"
                                                                                    value={montoRecibido}
                                                                                    onChange={handleMontoRecibidoChange}
                                                                                   
                                                                                />
                                                                        </fieldset>


                                                                            {// EN ESTE CODIGO MUESTRO EL VALOR A DEVOLVER
                                                                        }
                                                                        <fieldset>
                                                                                <legend>MONTO A DEVOLVER</legend>
                                                                                <input
                                                                                    type="text"
                                                                                    id="montoDevolver"
                                                                                    value={montoDevolver.toFixed(2)} // Asegúrate de que el valor sea decimal
                                                                                    disabled
                                                                                   
                                                                                />
                                                                        </fieldset>
                                                                        

                                                                    

                                                                    </div>
                                                                    <tr>
                                                                        <td>
                                                                        

                                                                        {
                                                                            //CODIGO PARA CONTROLAR QUE EL TOTAL RECIBIDO SEA MAYOR O IGUAL AL TOTAL DEL PEDIDO
                                                                        }
                                                                                <button 
                                                                                        className='btnPost' 
                                                                                        onClick={() => {
                                                                                            const total = Number(pedido.total);
                                                                                            const recibido = Number(montoRecibido);
                                                                                            
                                                                                            if (recibido >= total) {
                                                                                                //alert('este codigo');
                                                                                            handleUpdateTextCobro(pedido.idPedido, recibido, montoDevolver);
                                                                                            } else {
                                                                                                Swal.fire({
                                                                                                    title: 'Valor recibido es menor al total del pedido!',
                                                                                                    text: 'Facturación',
                                                                                                    icon: 'success',
                                                                                                    iconHtml: '!',
                                                                                                    customClass: {
                                                                                                      icon: 'swal2-warning-icon'
                                                                                                    }
                                                                                                  });


                                                                                            }
                                                                                        }}
                                                                                        >
                                                                                        Guardar 
                                                                                        <FontAwesomeIcon icon={faSave} />
                                                                                        </button>



                                                                        </td>
                                                                       
                                                                       <td>

                                                                        ----------

                                                                       </td>


                                                                       <td>
                                                                          <button className='btnPost'  onClick={cerrarModalPasarelaPago} > Cancelar
                                                                          <FontAwesomeIcon icon={faCancel} />
                                                                           </button>
                                                                        </td>

                                                                    
                                                                    
                                                                    </tr>
                                                                    
                                                                   
                                                                    
                                                               
                                                               
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}



                    </div>
   
);
};
